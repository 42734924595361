const JanelaPromocao = (props) => {
    return(
        <div className="fixed z-50 top-28 left-6 rounded-xl border border-blue-900 flex flex-col gap-6 bg-neutral-200 p-5">
            <button onClick={() => props.setPromocaoIsOpen(false)} className="w-1/5 m-auto rounded-xl border border-blue-900">Fechar</button>
            <h1 className="text-xl font-semibold">Experimente a Telser gratuitamente!</h1>
            <div className="text-center">
                <h2 className="underline">Não é necessário cartão de crédito.</h2>
                <h3 className="font-bold">É grátis mesmo!</h3>
            </div>
            <div>
                <div>Faça agora seu cadastro e ganhe:</div>
                <ul >
                    <li>&#10003; Certificado SSL grátis;</li>
                    <li>&#10003; Migração gratuita;</li>
                    <li>&#10003; Contas de e-mail profissional!</li>
                </ul>
            </div>
            <button onClick={() => (props.setNavegador('formulario'), props.setPromocaoIsOpen(false))} className='p-3 text-white bg-blue-900 rounded-xl text-xl drop-shadow-2xl'>Teste grátis 10 dias</button>
        </div>
    )
}

export default JanelaPromocao