const Rodape = () => {
    return(
        <div className="text-center bg-slate-50 py-10">
            <div>
                <a href="https://telser.com.br/politica.pdf" className="hover:font-bold text-blue-800 underline">Política de Privacidade</a> - <a href="https://telser.com.br/termos.pdf" className="hover:font-bold text-blue-800 underline">Termos de Uso</a>
            </div> 
            <div>Uma empresa do Grupo <a href="https://wtnpay.com" className="hover:font-bold text-blue-800 underline">WTN Pay</a></div>
        </div>
    )
}

export default Rodape