import { useState } from "react"

const Formulario = (props) => {
    const [pessoa, setPessoa] = useState('fisica')
    const [contrato, setContrato] = useState(false)

    return(
        <div className="flex w-screen h-auto overflow-y-scroll bg-slate-50 items-center justify-center pt-28 pb-10">
            <div className='flex flex-col w-96 h-full p-6 items-center bg-neutral-200 border border-blue-900 rounded-2xl drop-shadow-xl gap-6'>
                <h1 className="text-2xl sm:text-3xl">Contratar Hospedagem</h1>
                <form method="POST" action="https://telserbeckend.onrender.com/rotasForm/addform" className="flex flex-col w-auto justify-center gap-1">
                    <select name="plano" className="w-5/12">
                        <option>Selecione um Plano</option>
                        {props.planos.map(item => {
                            return(
                                <option key={item.nome} value={item.nome}>{item.nome}</option>
                            )
                        })}
                    </select>
                    <div className="flex w-auto gap-3">
                        <label>Dominio: </label>
                        <input name="dominio" type="text" className="rounded-lg border border-blue-900 p-1" />
                    </div>
                    <div className="flex w-auto gap-3">
                        <label>Nome: </label>
                        <input name="nome" type="text" className="rounded-lg border border-blue-900 p-1" />
                    </div>
                    <div className="flex w-auto gap-3">
                        <label>E-mail: </label>
                        <input name="email" type="text" className="rounded-lg border border-blue-900 p-1" />
                    </div>
                    <div className="flex w-auto gap-3">
                        <label>Celular: </label>
                        <input name="celular" type="text" className="rounded-lg border border-blue-900 p-1" />
                    </div>
                    <div>
                        <select onChange={(e) => setPessoa(e.target.value)} className="mb-1">
                            <option value='fisica'>Pessoa Fisica</option>
                            <option value='juridica'>Pessoa Juridica </option>
                        </select>
                        {pessoa == 'fisica' ? 
                            <div>
                                <label>CPF: </label>
                                <input name="cpf" type="text" className="rounded-lg border border-blue-900 p-1" />
                            </div>
                            :
                            <div>
                                <div>
                                    <label>CPF: </label>
                                    <input name="cnpj" type="text" className="rounded-lg border border-blue-900 p-1" />
                                </div>
                                <div>
                                    <label>CNPJ: </label>
                                    <input type="text" className="rounded-lg border border-blue-900 p-1" />
                                </div>
                                <div>
                                    <label>Razão Social: </label>
                                    <input name="rs" type="text" className="rounded-lg border border-blue-900 p-1" />
                                </div>
                            </div>
                        }
                    </div>
                    <div className="flex flex-col gap-3">
                        <div className="flex gap-3">
                           <input type="checkbox" onChange={(e)=> e.target.checked == true ? setContrato(true) : setContrato(false)} />
                            <label>Ao marcar a caixa ao lado você afirma que leu e concordou com o <a className="underline text-blue-800" target="__blanck" href="https://telser.com.br/contrato.pdf">Contrato de Serviços.</a></label> 
                        </div>
                        <input type="submit" className={contrato == true ? 'flex w-2/5 m-auto p-1 text-white bg-blue-900 rounded-xl text-xl drop-shadow-2xl' : 'flex w-2/5 m-auto p-1 text-white bg-zinc-900 rounded-xl text-xl drop-shadow-2xl'} onClick={(e)=> contrato == false ? e.preventDefault() : props.setNavegador('enviado')} />
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Formulario