import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Nav from './Nav.js'
import Home from './Home.js'
import Hospedagem from './Hospedagem.js'
import Contatos from './Contatos.js'
import JanelaPromocao from './JanelaPromocao.js'
import Formulario from './Formulario.js'
import ObrigadoContratar from './ObrigadoContratar.js'
import Rodape from './Rodape.js'
import './App.css'
import { useState } from 'react'

function App() {
  const planos = [
    {
      nome: 'GO',
      valor: '9,50',
      disco: 'Ilimitado',
      transferencia: 'Ilimitada',
      ssl: 'Grátis',
      mySQL: '2',
      emails: '10',
      setup: 'Grátis',
      dominios: 1,
      subdominios: 'Ilimitados',
    },
    {
      nome: 'GO 1',
      valor: '11,50',
      disco: 'Ilimitado',
      transferencia: 'Ilimitada',
      ssl: 'Grátis',
      mySQL: '5',
      emails: 'Ilimitada',
      setup: 'Grátis',
      dominios: 1,
      subdominios: 'Ilimitados',
    }
  ]

  const [promocaoIsOpen, setPromocaoIsOpen] = useState(true)
  const [navegador, setNavegador] = useState('inicio')

  return (
    <div className='h-screen bg-gradient-to-r from-blue-900 to-indigo-950'>
      <Nav setNavegador={setNavegador} />
      {navegador == 'inicio' ? <Home /> : null}
      {navegador == 'inicio' ? <Hospedagem planos={planos} setNavegador={setNavegador} /> : null}
      {navegador == 'formulario' ? <Formulario planos={planos} setNavegador={setNavegador} /> : null}
      {navegador == 'enviado' ? <ObrigadoContratar /> : null}
      <Rodape />
      {promocaoIsOpen ? <JanelaPromocao setPromocaoIsOpen={setPromocaoIsOpen} setNavegador={setNavegador} /> : null}
    </div>
  )
}

export default App;
