import imgHome from './imgs/hospedagens-sites-rapida-barata.png'

const Home = () => {
    return(
        <main id='home' className='flex flex-col w-screen text-center gap-20 text-white pt-24 pb-6'>
            <div className='flex flex-col items-center justify-around gap-10'>
                <div className='flex flex-col gap-6 items-center'>
                    <h2 className='text-5xl font-bold'>Hospedagem Segura e rápida</h2>
                    <ul className='w-10/12 list-disc'>
                        <li className='text-left'>Espaço em disco ilimitado.</li>
                        <li className='text-left'>Transferência mensal ilimitado.</li>
                        <li className='text-left'>SSL Grátis</li>
                        <li className='text-left'>Instale os principais Apps em um click</li>
                        <li className='text-left'>Suporte 24/7</li>
                    </ul>
                </div>
            </div>
        </main>
    )
}

export default Home