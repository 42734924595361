import { useState } from "react"
import iconeMenu from './imgs/iconeMenu.png'
import iconeMenuX from './imgs/iconeMenuX.png'

const Nav = (props) => {
    const [menuIsOpen, setMenuIsOpen] = useState(false)
    return(
        <div className={`fixed top-0 w-screen text-white duration-700 overflow-clip z-50 flex h-auto justify-around bg-blue-700 py-3`}>
                <div onClick={() => props.setNavegador('inicio')} className="flex text-5xl font-being font-bold">Tel<div className="text-red-600">Ser</div></div>
                <a target="_blenck" href="https://wa.me/551141724002"><img className="w-12" src='https://img.icons8.com/?size=100&id=16713&format=png&color=000000' /></a>
        </div>
    )
}

export default Nav