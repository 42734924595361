import imgServidor from './imgs/hospedagem-sites-custo-beneficio.png'

const Hospedagem = (props) => {
    return(
        <div id='hospedagem' className='flex flex-col w-full items-center py-10 bg-slate-50'>
            <div className='flex flex-col w-full items-center gap-8'>
                <h1 className='text-3xl font-bold'>Planos de hospedagem</h1>
                {props.planos.map((item) => {
                    return(
                        <div key={item.nome} className='flex flex-col w-10/12 py-14 items-center bg-neutral-200 border border-blue-900 rounded-2xl drop-shadow-xl gap-6'>
                            <div className='text-4xl font-extrabold'>{item.nome}</div>
                            <div className='flex text-2xl font-bold'>R$<div className='text-7xl'>{item.valor.split(',')[0]}</div>,<div>{item.valor.split(',')[1]}</div></div>
                            <button onClick={() => props.setNavegador('formulario')} className='p-3 text-white bg-blue-900 rounded-xl text-xl drop-shadow-2xl'>Teste grátis 10 dias</button>
                            <div>
                                <h1 className='text-lg underline font-medium'>Principais Recursos</h1>
                                <ul>
                                    <li>&#10003; Espaço em Disco {item.disco}</li>
                                    <li>&#10003; Transferência {item.transferencia}</li>
                                    <li>&#10003; SSL {item.ssl}</li>
                                    <li>&#10003; MySQL {item.mySQL}</li>
                                    <li>&#10003; cPanel Português {item.postgreSQL}</li>
                                    <li>&#10003; Contas de e-mails {item.emails}</li>
                                    <li>&#10003; Setup {item.setup}</li>
                                    <li>&#10003; Domínios {item.dominios}</li>
                                    <li>&#10003; Subdomínios com SSL {item.subdominios}</li>
                                    <li>&#10003; PHP 4 PHP 5 PHP 7 PHP 8</li>
                                </ul>
                            </div>
                            <h2 className='text-xl font-semibold'>Plano Mensal</h2>
                        </div>
                    )
                })}
            </div>
            <div className='flex flex-col w-10/12 mt-10 py-14 items-center bg-neutral-200 border border-blue-900 rounded-2xl drop-shadow-xl gap-6'>
                <div className='text-4xl font-extrabold'>Nossos Servidores possuem:</div>
                <div>
                    <h1>Principais Recursos</h1>
                    <ul className='columns-3 text-xs'>
                        <li>✓ PHP 4 PHP 5 PHP 7 PHP 8 (todas as versões)</li>
                        <li>✓ WebMail / POP3 / SMTP</li>
                        <li>✓ Endereço Pega-Tudo</li>
                        <li>✓ Bloquear Endereço</li>
                        <li>✓ Redirecionamentos</li>
                        <li>✓ Mailing Lists</li>
                        <li>✓ Modificar MX Entry</li>
                        <li>✓ Spam Assassin</li>
                        <li>✓ Webalizer Estatísticas de Acesso WEB</li>
                        <li>✓ Webalizer Estatísticas de Acesso FTP</li>
                        <li>✓ Analog Estatísticas de Acesso</li>
                        <li>✓ Últimas Visitas</li>
                        <li>✓ Estatísticas gráficas</li>
                        <li>✓ Transferência (Banda Utilizada)</li>
                        <li>✓ Logs de Erros</li>
                        <li>✓ Liberdade para usar Scripts</li>
                        <li>✓ Interchange Shopping Cart (Loja Virtual)</li>
                        <li>✓ Agora Cart (Loja Virtual)</li>
                        <li>✓ Fórum (Bulletin Board)</li>
                        <li>✓ Salas de Bate-Papo (HTML/Java)</li>
                        <li>✓ Node.js</li>
                        <li>✓ Gerador de HTML Randômico</li>
                        <li>✓ Livro de Visitas Avançado</li>
                        <li>✓ Contador</li>
                        <li>✓ Relógio</li>
                        <li>✓ Contagem Regressiva</li>
                        <li>✓ Acesso total ao CGI-BIN</li>
                        <li>✓ Secure Server Includes (SSI)</li>
                        <li>✓ Cron Jobs</li>
                        <li>✓ DNS Wildcards</li>
                        <li>✓ PHPNuke</li>
                        <li>✓ Softaculous (Sistemas grátis para o site)</li>
                        <li>✓ HTTP streamming (Áudio e Vídeo)</li>
                        <li>✓ FTP Anônimo</li>
                        <li>✓ FTP dedicado</li>
                        <li>✓ Clone do FormMail</li>
                        <li>✓ CGI email</li>
                        <li>✓ Sistema de Busca Interno</li>
                        <li>✓ Indexar Sistema de Busca Interno</li>
                        <li>✓ Gerenciador de Banners</li>
                        <li>✓ Ferramentas Completas de Backup</li>
                        <li>✓ Sala de Bate-Papo (phpMyChat)</li>
                        <li>✓ Proteger diretórios com senha</li>
                        <li>✓ Alterar senha de acesso</li>
                        <li>✓ Páginas de erros customizáveis</li>
                        <li>✓ Gerenciador de Redirecionamentos</li>
                        <li>✓ Mime Types e Apache Handlers</li>
                        <li>✓ SoftAculous Deluxe</li>
                        <li>✓ Metacadastro grátis</li>
                        <li>✓ HotLink Protection</li>
                        <li>✓ Gerenciador de Sub-Domínios</li>
                        <li>✓ Estatísticas dos Sub-Domínios</li>
                        <li>✓ Acesso SSH (Shell) Telnet mediante solicitação</li>
                        <li>✓ Tarefas Cron</li>
                        <li>✓ GPG Keys</li>
                        <li>✓ Banco de dados</li>
                        <li>✓ Gerenciador MySQL</li>
                        <li>✓ phpMyAdmin</li>
                        <li>✓ cPANEL X em português</li>
                        <li>✓ Sistema Operacional Linux</li>
                        <li>✓ Perl Última versão</li>
                        <li>✓ Módulos Perl</li>
                        <li>✓ PHP Última versão</li>
                        <li>✓ MySQL Última versão</li>
                        <li>✓ Fórum de Discussão</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Hospedagem