const ObrigadoContratar = () => {
    return(
        <div className="flex w-screen overflow-y-scroll bg-slate-50 items-center justify-center pt-28 py-16">
            <div className="className='flex flex-col-reverse w-11/12 h-full p-6 items-center bg-neutral-200 border border-blue-900 rounded-2xl drop-shadow-xl gap-6'">
                <h1 className="text-3xl my-16 text-center">Seu cadastro foi efetuado com sucesso!</h1>
                <div className="h-full text-center">Enviaremos os dados da ativaçao da sua conta no e-mail cadastrado, na dúvida entre em contato com nosso suporte online.<div>Telser Brasil</div></div>
            </div>
        </div>
    )
}

export default ObrigadoContratar